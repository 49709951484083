<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <el-table :data="coursewareList" border fit highlight-current-row>
      <el-table-column label="姓名" align="left" prop="userName"></el-table-column>
      <el-table-column label="学号" align="left" prop="userSid"></el-table-column>
      <el-table-column label="作品名称" align="left" prop="title"></el-table-column>

      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click="downloadFile(scope.row)">下载</el-button>
          <el-button size="small" type="primary" plain @click="delUpload(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-dialog>
</template>

<script>
  import { listTaskUpload,delUpload } from '@/api/research/curriculum'
  import { getToken } from '@/utils/auth'

  export default {
    name:"PreviewAccessory",
    props: {
      show: {
        default: false,
        type: Boolean
      },
      taskID: {
        default: "",
        type: String
      },
    },
    data () {
      return {
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 30, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        resetUse: {},
        coursewareList:[],
        Authorization:""
      }
    },
    methods: {
      downloadFile(row) {
        let pathUrl = process.env.VUE_APP_API_ADDRESS + "/task/downTaskFile?Authorization="+this.Authorization+
          "&taskId="+row.taskId + "&userId="+row.userId
        let link = document.createElement('a')  // 创建a标签
        link.style.display = 'none'
        // Authorization="token"
        link.href = pathUrl // 设置下载地址
        link.setAttribute('download', '') // 添加downLoad属性
        document.body.appendChild(link)
        link.click()
      },
      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.listTaskUpload()
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.listTaskUpload()
      },
      openDialog () {
        this.listTaskUpload()
        this.Authorization = getToken()
      },

      async listTaskUpload () {
        const res = await listTaskUpload(
          {
            taskId: this.taskID,
            pageNum: this.pagination.currentPage,
            pageSize: this.pagination.pageSize,
          })
        this.pagination.total = res.body.total
        this.coursewareList = res.body.list
      },

      close () {
        this.$emit('close')
      },

      // 删除课件
      delUpload (row) {
        this.$confirm('是否确定删除此学生作品', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            const response = await delUpload({
              taskId: row.taskId,
              userId:row.userId,
            })
            if(response.state === 'success') {
              window.$msg('删除成功')
              this.listTaskUpload()
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../style/dialog.scss";
</style>
